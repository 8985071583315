import http from "@/http";
import {endLoadTable, Forbidden, getUrl, notifySuccess} from "@/components/composables/functions";
import {endLoading} from "@/components/composables/spinners";

export const state = {
    listAll: [],
    listCards: [],
    pagination: {
        page: 0,
        limit: 25,
        total: 1,
        partial: 0,
    },
    showModalForm: false
}

export const mutations = {
    getList(state, newValue) {
        state.listAll = newValue
        state.listCards = [
            ...state.listCards,
            ...newValue.message
        ]
        state.pagination = {
            page: newValue.page,
            limit: newValue.limit,
            total: newValue.total,
            partial: newValue.partial,
        }
    },

    getCards(state, newValue) {
        state.listAll = newValue
        state.listCards = [
            ...state.listCards,
            ...newValue.message
        ]
        state.pagination = {
            page: newValue.page,
            limit: newValue.limit,
            total: newValue.total,
            partial: newValue.partial,
        }
    },

    deleteCard(state, id) {
        state.listCards = state.listCards.filter(card => card.id !== id);
    },

    getValues(state, newValue) {
        state.listAll = newValue
    },

    resetCards(state) {
        state.listCards = [];
    },

    resetValues(state) {
        state.listAll = [];
        state.listCards = [];
    },

    setPagination(state, value) {
        state.pagination = value;
    },

    changeShowModalForm(state, value) {
        state.showModalForm = value;
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    getApi({commit}, url) {
        http.get(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('getList', response.data);
                localStorage.setItem('token', response.data.token)
                endLoadTable();
                if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
            })
            .catch(error => {
                endLoadTable();
                console.error('store - getApi: ', error);
                Forbidden(error);
            })
    },

    getCards({commit}, url) {
        if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'block';
        http.get(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('getCards', response.data);
                localStorage.setItem('token', response.data.token)
                endLoadTable();
                if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
            })
            .catch(error => {
                endLoadTable();
                console.error('store - getCards: ', error);
                Forbidden(error);
            })
    },

// eslint-disable-next-line
    async insert({dispatch, commit}, data) {
        let url = data.url;
        let formData = data.formData;
        return await http.post(url, formData, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then((response) => {
                commit('resetCards')
                notifySuccess(response.data.message);
                return true;
            })
            .catch(error => {
                console.error('store - insert: ', error);
                Forbidden(error);
                return false;
            })
            .finally(() => {
                endLoading('form', 'save');
            })
    },

    async delete({dispatch}, data) {
        const session = JSON.parse(localStorage.getItem(data.session));
        const id = data.id;
        let url = `${session.url}/${id}`;
        return await http.delete(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then((response) => {
                url = getUrl(data.session)
                dispatch('getApi', url)
                notifySuccess(response.data.message);
            })
            .catch(error => {
                console.error('store - delete: ', error);
                Forbidden(error);
            })
    }
}
